import React from "react";

const sneakPeek = () => {
  return (
    <div className="sneekpeek mb-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center">Take a sneak-peek of the Project</h1>
            <div
              id="sneakpeekcarousel"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    class="d-block w-100"
                    src="./sneakpeek/sattva-lavita-renders-1.webp"
                    alt="First slide"
                  />
                </div>
                <div class="carousel-item ">
                  <img
                    class="d-block w-100"
                    src="./sneakpeek/sattva-lavita-renders-2.webp"
                    alt="First slide"
                  />
                </div>
                <div class="carousel-item ">
                  <img
                    class="d-block w-100"
                    src="./sneakpeek/sattva-lavita-renders-3.webp"
                    alt="First slide"
                  />
                </div>
                <div class="carousel-item ">
                  <img
                    class="d-block w-100"
                    src="./sneakpeek/sattva-lavita-renders-4.webp"
                    alt="First slide"
                  />
                </div>
                <div class="carousel-item ">
                  <img
                    class="d-block w-100"
                    src="./sneakpeek/sattva-lavita-renders-5.webp"
                    alt="First slide"
                  />
                </div>
                <div class="carousel-item ">
                  <img
                    class="d-block w-100"
                    src="./sneakpeek/sattva-lavita-renders-6.webp"
                    alt="First slide"
                  />
                </div>
                <div class="carousel-item ">
                  <img
                    class="d-block w-100"
                    src="./sneakpeek/sattva-lavita-renders-7.webp"
                    alt="First slide"
                  />
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#sneakpeekcarousel"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#sneakpeekcarousel"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default sneakPeek;
