import React from "react";
import { Icon } from "@iconify/react";

const herosection = () => {
  return (
    <div className="herosection">
      <div className="model_pageload">
        <div
          class="modal fade "
          id="pageload_model"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                {/* <div className="good_news">Good news</div> */}
                {/* <img
                src="./images/launch_rocket.png"
                alt=""
                className="img-fluid"
              /> */}
                <div className="phase_two">
                  <h5 className="hurry">
                    Hurry up! <br />
                  </h5>
                  <h3> Pre bookings open now..</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="mybutton">
        <a href="#enquiry" className="enquiry">
          Enquiry Now
        </a>
      </div>

      {/* <div id="whatsappBtn">
        <a
          href="https://api.whatsapp.com/send?phone=6361156201&text=sattva"
          className="whatsappBtn"
        >
          <Icon className="icon" icon="ic:outline-whatsapp" />
        </a>
      </div> */}

      {/* <div class="wh-api">
        <div class="wh-fixed whatsapp-pulse">
          <a href="https://api.whatsapp.com/send?phone=6361156201&text=Hi! this is testing">
            <button class="wh-ap-btn"></button>
          </a>
        </div>
      </div> */}

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <img src="./sattva_hero_2.jpg" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <div className="container mt-3" id="enquiry">
        <div className="row">
          <div className="col-md-3 bg-image">
            <h1>
              Request a <br /> Call Back
            </h1>
          </div>
          <div className="col-md-9">
            <form
              name="contact"
              method="post"
              data-netlify="true"
              onSubmit="submit"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="row">
                <div className="col-md-6">
                  <input
                    placeholder="First Name *"
                    className="form-control"
                    type="text"
                    name="name"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="Last Name *"
                    className="form-control"
                    type="text"
                    name="lastname"
                    required
                  />
                </div>
              </div>
              <div className="row mt-0 mt-md-4">
                <div className="col-md-6">
                  <input
                    placeholder="Email address *"
                    className="form-control"
                    type="text"
                    name="email"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="Contact number *"
                    className="form-control"
                    type="text"
                    name="contact number"
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <button className="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7">
            <h2>Welcome to Sattva La Vita!</h2>
            <p>
              In the idyllic location of Hennur, Bengaluru’s most happening
              suburb, come home to the sheer joy of beautifully laid out luxury
              Row Houses from a builder of great repute. Built on 3 pristine
              acres, are 63 supremely elegant Row Houses with a spacious
              built-up area of 3,260 sq ft. It offers every conceivable amenity
              to make your life a dream come true. With the ideal mix of privacy
              when you need it and secure community living when you step out,
              get the best of both worlds at Sattva La Vita.
            </p>
            <p>
              Everything about Sattva La Vita is as private as it gets. Each
              spacious Row House has independent walls, so that you are far away
              from prying eyes. The backyards are large and spacious, extending
              over 10 feet to give you your very own garden space. Each Row
              House is exquisitely designed with 4 bedrooms, a gym, an
              entertainment zone and a family lounge, for quality time with your
              loved ones. The homes comprise of 2 levels for ease of movement
              and offer 2 car parks. Your very own private terrace on the top
              level, makes for quiet evenings, watching the sunset.
            </p>
          </div>
          <div className="col-md-5">
            <div className="bg_blue">
              <h5>Project Highlights</h5>
              <ul>
                <li>Exclusive 9700 sq. ft clubhouse with supreme amenities</li>
                <li>
                  Ground plus 2 levels with a large backyard and open terrace.
                </li>
                <li>
                  4 bedrooms + gym + entertainment + family lounge + domestic
                  help room
                </li>
                <li>2 car parks</li>
                <li>Walkways all around with zero vehicle movement</li>
                <li>Independent walls for the ultimate privacy</li>
                <li>United International School and Degree College – 1 min.</li>
                <li>Faran Group of Management – 2 min.</li>
                <li>Byg Brewski – 3 min.</li>
                <li>Poorna Prajna Education Center – 3 min.</li>
                <li>
                  Army Institute of Fashion & Design and Hotel Management – 4
                  min.
                </li>
                <li>Mall of Bengaluru – 5 min.</li>
                <li>Manipal Academy of Higher Education – 7 min.</li>
                <li>The Leela Hotel – 7 min.</li>
                <li>KNS Institute of Technology – 9 min.</li>
                <li>Koshys Group of Institutions – 9 min.</li>
                <li>Manipal Hospital – 20 min.</li>
                <li>Aster CMI Hospital – 20 min.</li>
                <li>Hebbal Outer Ring Road – 25 min.</li>
                <li>Bengaluru International Airport – 30 min.</li>
                <li>Phoenix Market City – 30 min.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default herosection;
