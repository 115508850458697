import React from "react";

const navbar = () => {
  return (
    <div className="navbars">
      <nav className="text-center">
        <img className="img-fluid" src="./Sattva-logo-color.gif" alt="" />
      </nav>
    </div>
  );
};

export default navbar;
