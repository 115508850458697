import "./App.css";
import Herosection from "./components/homepage/herosection";
import Amenities from "./components/homepage/amenities";
import ProjectPlans from "./components/homepage/projectPlans";
import SneakPeek from "./components/homepage/sneakPeek";
import LocationMap from "./components/homepage/locationMap";
import Navbar from "./components/navbar";
import Footer from "./components/footer";

function App() {
  return (
    <div>
      <Navbar />
      <Herosection />
      <Amenities />
      <ProjectPlans />
      <SneakPeek />
      <LocationMap />
      <Footer />
    </div>
  );
}

export default App;
