import React from "react";

const amenities = () => {
  return (
    <div className="amenities">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Amenities at Sattva La Vita</h1>

            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    class="d-block w-100"
                    src="./amenities/swimming_pool.webp"
                    alt="First slide"
                  />
                  <div className="carousel_heading">Swimming Pool</div>
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/kids_play.webp"
                    alt="Second slide"
                  />
                  <div className="carousel_heading">Kids Play Area</div>
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/gym.webp"
                    alt="Third slide"
                  />
                  <div className="carousel_heading">Outdoor Gym</div>
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/yoga.webp"
                    alt="Third slide"
                  />
                  <div className="carousel_heading">Yoga/meditation</div>
                </div>

                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/court.webp"
                    alt="Third slide"
                  />
                  <div className="carousel_heading">Multi-purpose court</div>
                </div>

                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/partyarea.webp"
                    alt="Third slide"
                  />
                  <div className="carousel_heading">Indoor Party Area</div>
                </div>

                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/tabletennis.webp"
                    alt="Third slide"
                  />
                  <div className="carousel_heading">Table Tennis Room</div>
                </div>

                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/baseball.webp"
                    alt="Third slide"
                  />
                  <div className="carousel_heading">Half Baseball court</div>
                </div>

                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/indoorgames.jpg"
                    alt="Third slide"
                  />
                  <div className="carousel_heading">Indoor Games</div>
                </div>

                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/outdoorgym.jpg"
                    alt="Third slide"
                  />
                  <div className="carousel_heading">Outdoor Gym</div>
                </div>

                <div class="carousel-item">
                  <img
                    class="d-block w-100"
                    src="./amenities/outdoorparty.jpg"
                    alt="Third slide"
                  />
                  <div className="carousel_heading">Outdoor Party</div>
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default amenities;
