import React from "react";

const locationMap = () => {
  return (
    <div className="locationMap pb-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center">Location Map</h1>
            <img src="../location_map.png" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default locationMap;
