import React from "react";

const footer = () => {
  return (
    <div>
      <div className="container-fluid">
        <p className="disclimer">
          Disclaimer The Project is being Developed by Salarpuria sattva group
          The Project is registered as "Salarpuria LA VITA" with RERA No:
          PRM/KA/RERA/1251/446/PR/041223/006446, available at
          website:http://rera.karnataka.gov.in. The information is presented as
          general information and no warranty is expressly or impliedly given
          that the completed development will comply in any degree with such
          artist’s impression or anticipated appearance.Recipients are advised
          to apprise themselves of the necessary and relevant information of the
          project(s)/offer(s) prior to making any purchase decisions. The Sale
          is subject to terms of Application Form and Agreement for Sale. All
          specifications of the unit shall be as per the final agreement between
          the Parties. *The prices mentioned are an indicative Agreement Value.
          Stamp Duty Registration, GST and Other Charges over and above the
          Agreement Value. The amenities mentioned here are a part of Birla
          Advaya and proposed future development. This is an initiative by Smita
          an Individual Channel Partner RERA Regn. No.
          PRM/KA/RERA/1251/310/AG/220521/002906 to provide information about
          Salarpuria LA VITA is not to be construed as an official site of Birla
          Advaya. Distance mentioned is as per google map. Drive time refers to
          the time taken to travel by a car based on normal traffic conditions
          during non-peak hours as per Google maps may vary according to the
          current traffic conditions. Information provided is based on Online
          sources, the developer does not provide any guarantee on the same.
        </p>
      </div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <img
                src="./Footer-logo-colored.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default footer;
