import React from "react";
import ImageGallery from "react-image-gallery";

const projectPlans = () => {
  const tpEast = [
    {
      original: "./floorplan/Signet_brochure-21-1-scaled.webp",
      thumbnail: "/floorplan/Signet_brochure-21-1-scaled.webp",
    },
    {
      original: "./floorplan/Signet_brochure-22-1-scaled.webp",
      thumbnail: "/floorplan/Signet_brochure-22-1-scaled.webp",
    },
    {
      original: "./floorplan/Signet_brochure-23-1-scaled.webp",
      thumbnail: "/floorplan/Signet_brochure-23-1-scaled.webp",
    },
  ];

  return (
    <div className="projectPlans">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h1>
              The Project <br /> <span> Plans</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul
              class="nav nav-tabs justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  href="#masterplan"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Master Plan
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#towerplan"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Tower Plan
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#unitplan"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Unit Plan
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="masterplan"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <img
                  class="img-fluid mt-3"
                  src="./masterplan.jpg"
                  alt="Second slide"
                />
              </div>
              <div
                class="tab-pane fade"
                id="towerplan"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      East
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      West
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#contact"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      North
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <img
                      class="d-block w-100"
                      src="./floorPlan/Signet_brochure-21-1-scaled.webp"
                      alt="First slide"
                    />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <img
                      class="d-block w-100"
                      src="./floorPlan/Signet_brochure-22-1-scaled.webp"
                      alt="First slide"
                    />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <img
                      class="d-block w-100"
                      src="./floorPlan/Signet_brochure-23-1-scaled.webp"
                      alt="First slide"
                    />
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="unitplan"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div
                  id="unitPlancur"
                  class="carousel slide"
                  data-ride="carousel"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="text-center font-weight-bold text-light bg-dark py-2 mt-3">
                        <h5>West Facing</h5>
                      </div>
                      <img
                        class="d-block w-100"
                        src="./unitplan/unitPlan_1.png"
                        alt="First slide"
                      />
                    </div>
                    <div class="carousel-item">
                      <div class="text-center font-weight-bold text-light bg-dark py-2 mt-3">
                        <h5>East Facing</h5>
                      </div>
                      <img
                        class="d-block w-100"
                        src="./unitplan/unitPlan_2.png"
                        alt="Second slide"
                      />
                    </div>
                    <div class="carousel-item">
                      <div class="text-center font-weight-bold text-light bg-dark py-2 mt-3">
                        <h5>North Facing</h5>
                      </div>
                      <img
                        class="d-block w-100"
                        src="./unitplan/unitPlan_3.png"
                        alt="Second slide"
                      />
                    </div>
                  </div>
                  <a
                    class="carousel-control-prev"
                    href="#unitPlancur"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#unitPlancur"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default projectPlans;
